body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.language-selector {
  display: flex;
  width: 100%;
}

.language-holder {
  flex: 1;
  padding: 10%;
}

.language-holder img {
  max-width: 100%;
}

.language-switcher {
  width: 10%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

.language-switcher img {
  position: absolute;
  margin-top: 25%;
  padding-right: 25%;
  width: 100%;
}
